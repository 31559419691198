import React from "react";
import PropTypes from "prop-types";
import "./FAQ.less";

const FAQ = ({ title, questions }) => {
  const [openIndex, setOpenIndex] = React.useState(-1);

  const toggleOpen = index => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className="faq">
      <h2 className="faq__title">{title}</h2>
      <ul className="faq__list">
        {questions.map((question, i) => {
          if (question.delimeter) {
            return (
              <li key={`faq-${i}`} className="faq__delimiter">
                {question.title}
              </li>
            );
          } else {
            const classNames = ["faq__answer"];
            if (openIndex === i) {
              classNames.push("faq__answer--open");
            }
            return (
              <li key={`faq-${i}`} className="faq__list-item">
                <h3 className="faq__question" onClick={() => toggleOpen(i)}>
                  {question.title}
                </h3>
                <p className={classNames.join(" ")}>{question.text}</p>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

FAQ.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default FAQ;
