import React, { useState, RefObject } from "react";
import PropTypes from "prop-types";

// @ts-ignore
import Play from "../../../assets/svg/Play.svg";

import "./Video.less";

const Video = ({ src, title, className = "" }) => {
  const ref: RefObject<HTMLVideoElement> = React.createRef();
  const onPlay = () => {
    setIsTouched(true);
    ref.current.play();
  };

  const baseUrl = "https://service.ludwigweise.de/cdn/stadthonig.shop";
  const sizes = ["480", "720", "1080"];
  const [isTouched, setIsTouched] = useState(false);
  const classNames = ["video", className];

  let conditionalProps = {};
  if (isTouched) {
    conditionalProps = {
      ...conditionalProps,
      controls: true,
      controlsList: "nodownload"
    };
  }

  return (
    <div className={classNames.join(" ")}>
      {isTouched === true ? null : (
        <div className="video__play" onClick={() => onPlay()}>
          <Play />
        </div>
      )}
      <video
        ref={ref}
        className="video__video"
        {...conditionalProps}
        title={title}
        poster={`${baseUrl}/${src}/Poster.jpg`}
        preload="none"
        playsInline={true}
      >
        {sizes.map(x => (
          <source
            key={`${src}-${x}`}
            src={`${baseUrl}/${src}/${src}-${x}.mp4`}
            type="video/mp4"
            media={x !== "1080" ? `all and (max-width: ${x}px)` : undefined}
          />
        ))}
        <div className="video__not-supported">
          Video in your browser is not supported. Please use a modern browser.
        </div>
      </video>
    </div>
  );
};

Video.protoTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Video;
